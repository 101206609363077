.privacy-policy {
    text-align: left;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .privacy-policy h1, .privacy-policy h2 {
    color: #333;
  }
  
  .privacy-policy p, .privacy-policy ul {
    color: #666;
    line-height: 1.6;
  }
  
  .privacy-policy ul {
    padding-left: 20px;
  }
  