/* App-wide styles */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* HomePage styles */
.homepage {
  padding: 20px;
}

.navigation ul {
  list-style: none;
  padding: 0;
}

.navigation li {
  display: inline;
  margin: 0 10px;
}

.navigation li a {
  color: #61dafb;
  text-decoration: none;
}

.welcome-section, .features-section, .download-section {
  margin: 20px 0;
}

.features-section ul {
  text-align: left;
  display: inline-block;
}

footer {
  margin-top: 20px;
  font-size: smaller;
}

/* Adjust the colors and fonts as needed to match your app's theme */
