.cp {
    text-align: left;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cp h1, .cp h2 {
    color: #333;
  }
  
  .cp p, .cp ul {
    color: #666;
    line-height: 1.6;
  }
  
  .cp ul {
    padding-left: 20px;
  }
  